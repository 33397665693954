<template>
  <v-card>
    <v-card-title>New API Key</v-card-title>

    <v-card-text class="my-8">
      <InputTextField
        v-if="!createdKey"
        label="DESCRIPTION"
        v-model="$v.apiKey.description"
      />
      <v-sheet v-else class="d-flex align-center pa-2" outlined rounded>
        <div class="flex-grow-1 mr-2">
          <label class="grey--text">CREATED KEY:</label>
          <div>{{ createdKey }}</div>
        </div>
        <v-btn v-if="canCopy" icon @click="copyToClipboard"
          ><v-icon small>mdi-content-copy</v-icon></v-btn
        >
      </v-sheet>
    </v-card-text>

    <v-card-actions>
      <DismissButton @click="$emit('cancel')">Cancel</DismissButton>
      <PrimaryButton @click="createDone">{{
        createdKey ? "Done" : "Create"
      }}</PrimaryButton>
    </v-card-actions>
  </v-card>
</template>


<script>

import { validationMixin } from "vuelidate"
import { required } from 'vuelidate/lib/validators'

export default {

  data() {
    return {
      apiKey: {
        description: null
      },

      createdKey: null,
    }
  },
  mixins: [validationMixin],
  validations: {
    apiKey: {
      description: { required }
    }
  },

  computed: {
    canCopy() {
      return window.navigator
    }
  },

  methods: {
    async createDone() {
      if (this.createdKey) {
        this.$emit('done')
      } else {
        await this.create()
      }
    },
    async create() {
      if (this.$v.apiKey.$invalid) {
        this.$v.apiKey.$touch()
        return
      }

      //await this.$helpers.refreshAccessToken();
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      const data = {
        description: this.apiKey.description
      }

      try {
        const response = await this.$http.post('/api/api_key', data, { headers })
        this.createdKey = response.data.key
      } catch (error) {
        console.log(error)
      }
    },
    async copyToClipboard() {
      await window.navigator.clipboard.writeText(this.createdKey)
    }
  }
}
</script>

<style scoped>
label {
  font-size: 10px;
}
</style>
<template>
  <div>
    <v-card class="ma-4" flat>
      <TitleBar>
        API Keys
        <template #right-items>
          <PrimaryButton @click="addKey">Add New</PrimaryButton>
        </template>
      </TitleBar>
      <SectionBar>
        <template #left-items>
          <SearchTextField v-model="search" />
        </template>
      </SectionBar>
      <v-data-table :headers="headers" :items="apiKeys" :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.active="{ value }">
          <v-chip small :color="value === 1 ? 'success' : 'warning'">{{
            value === 1 ? "Yes" : "No"
          }}</v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <!-- <template #item.actions="{ item }">
          <v-icon
            small
            class="mr-4"
            @click="edit(item.idenvironment)"
          >
            mdi-pencil
          </v-icon>
          <v-icon small @click="confirmDelete(item.idenvironment)">
            mdi-delete
          </v-icon>
        </template> -->
      </v-data-table>
    </v-card>

    <v-dialog
      max-width="600px"
      v-model="showNewKeyModal"
      :key="`new-${showNewKeyModal}`"
    >
      <NewKeySheet
        @done="keyAdded"
        @cancel="showNewKeyModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>

import NewKeySheet from '../../components/APIKey/NewKeySheet.vue'

export default {

  components: { NewKeySheet },
  
  data() {
    return {
      apiKeys: [],
      search: null,
      headers: [
        { value: "description", text: "Description" },
        { value: "active", text: "Active", width: "120px",
          sortable: false,
          filterable: false, },
        { value: 'key', text: 'Key'}
      ],

      showNewKeyModal: null
    }
  },

  mounted() {
    this.getApiKeys()
  },

  methods: {
    async getApiKeys() {
      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      }
      try {
        const response = await this.$http.get("/api/api_key", { headers });
        this.apiKeys = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    addKey() {
      this.showNewKeyModal = true
    },
    keyAdded() {
      this.showNewKeyModal = false
      this.getApiKeys()
    },
  }

}
</script>